<template>
  <div class="c-no-list">
    <img src="/image/daili-no-list.png" alt="">
    <p class="c-no-list-text">没有数据了~</p>
  </div>
</template>

<script>
  export default {
    name: 'NoList',
  }
</script>

<style lang="scss">
.c-no-list {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  img {
    width: 220px;
    height: 155px;
    object-fit: cover;
  }
  .c-no-list-text {
    text-align: center;
    color: $grey;
    font-size: 14px;
    padding-top: 10px;
  }
}
</style>
<template>
  <div class="v_main_layout v-is-main g-flex g-flex-column">

    <router-view class="v-main-container"/>

    <footer-layout/>
  </div>
</template>

<script>
  import FooterLayout from '../components/FooterLayout.vue'
  export default {
    components: { FooterLayout },
    data() {
      return {
        indexName: ['main', 'task', 'message', 'mycenter'],
      }
    },
    computed: {
      isInMain() {
        const routerName = this.$route.name
        return this.indexName.includes(routerName)
      },
    }
  }
</script>

<style lang="scss">
.v_main_layout {
  height: 100%;
  &.v-is-main {
    padding-bottom: 50px;
  }
  .v-main-container {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
import router from "@/router"
// {resetRouter}
const global= {
  //判断是否外链
  isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
  },
  // 是否展示公告 0 不需要展示  1需要展示  localStorage.getItem('isShowNotice') ? localStorage.getItem('isShowNotice') : 
  isShowNotice: '1',

  // 是否是App
  isApp: 0,

  // 设置展示公告
  setShowNotice(val) {
    // window.localStorage.setItem('isShowNotice', val)
    global.isShowNotice = val
  },
  
  config: {
    SYSTEM: {
      title: '',
      version: ''
    },
    AGENT_KEFU: {
      qq: '',
      wechat: ''
    },
    AGENT_BULLETIN: {
      bullent: '',
      bullent_title: ''
    }
  },

  token: localStorage.getItem('appToken'),

  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}, //用户信息

  ApiList: [],
  
  MenuList: [],

  allSliceFlag: false, //是否静音

  autoPlayFlag: false, //是否自动播放过一次

  loadingShow: false, //加载动画

  // 设置token
  setToken(token) {
    window.localStorage.setItem('appToken', token)
    global.token = token
  },
  
  // 设置用户信息
  setUserInfo(obj) {
    window.localStorage.setItem('userInfo', JSON.stringify(obj))
    global.userInfo = obj
  },
  // unreadMsgNum: 0,
  
  // 订单提示声音开关
  orderMusic: localStorage.getItem('orderMusic') ? localStorage.getItem('orderMusic') : '1', //用户信息

  // 设置订单声音提示
  setOrderMusic(val) {
    window.localStorage.setItem('orderMusic', val)
    global.orderMusic = val
  },

  // 没有被打开的平台id列表
  checkplatlist: localStorage.getItem('checkplatlist') ? JSON.parse(localStorage.getItem('checkplatlist')) : [],
  
  // 设置 checkplatlist
  setCheckplatlist(val) {
    global.checkplatlist.push(val)
    // 去重
    global.checkplatlist = global.checkplatlist.filter((item,index) => {
      return global.checkplatlist.indexOf(item) === index
    })
    window.localStorage.setItem('checkplatlist', JSON.stringify(global.checkplatlist) )
  },

   // 删除或者清空 checkplatlist
   setRemoveplatlist(id) {
    if(id) {
      global.checkplatlist.splice(global.checkplatlist.indexOf(id), 1) 
      window.localStorage.setItem('checkplatlist', JSON.stringify(global.checkplatlist))
      return
    } 
    window.localStorage.setItem('checkplatlist', '[]')
    global.checkplatlist = []
  },

  //退出登录
  logout() {
      localStorage.removeItem('appToken')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('orderMusic')
      localStorage.removeItem('isShowNotice')
      global.token = null
      global.MenuList = []
      global.ApiList = []
      router.push('/login')
    },
}

export default global
<template>
  <div id="app">
     <transition :name="transitionName">
      <router-view :key="$route.fullPath"/>
    </transition>

    <Loading color="#F9436B" v-show="$global.loadingShow"/>
  </div>
</template>

<script>
import Loading from './components/Loading.vue'
import { apiGetConfig } from '@/utils/api.js'
export default {
  components: { Loading },
  data() {
    return {
      transitionName: '',
      systemCodeArr: ['SYSTEM', 'AGENT_KEFU', 'AGENT_BULLETIN']
    }
  },
  created() {
    this.apiGetConfigHandel()
  },
  methods: {
    // 获取配置
    async apiGetConfigHandel() {
      const { success, data } = await apiGetConfig({ code_arr: this.systemCodeArr })
      if(!success) return
      this.$global.config = data.data
      document.title = data.data.SYSTEM.title
      console.log('config', this.$global.config)
    },
  },
  watch:{
    $route(to, from) {
      if(from.fullPath === '/') return this.transitionName = '';
      if(from.meta.index == to.meta.index) return this.transitionName = '';
      if(to.meta.index > from.meta.index) {  //前进
          this.transitionName = 'slide-left';
        }else { //后退
          this.transitionName = 'slide-right';
        }
    } 
  }
}
</script>
<style lang="scss">
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: constant(safe-area-inset-bottom); //苹果底部安全距离
  padding-bottom: env(safe-area-inset-bottom); //苹果底部安全距离
}

body,html,#app {
  width: 100%;
  height: 100%;
}

.slide-right-enter-active,
.slide-right-leave-active {
    will-change: transform;
    transition: all .3s;
    position: absolute;
    width:100%;
    left:0;
}

.slide-left-enter-active,
.slide-left-leave-active {
    will-change: transform;
    transition: all .3s;
    position: absolute;
    width:100%;
    left:0;
}

// 前进
.slide-left-enter {
    transform: translateX(100%);
}
.slide-left-leave-active {
    transform: translateX(-100px);
    // opacity: 0;
}

// 后退
.slide-right-enter {
    transform: translateX(-100px);
}
.slide-right-leave-active {
    transform: translateX(0);
    // opacity: 0;
}
</style>

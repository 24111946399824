<template>
  <div class="c_loading">
    <van-loading class="c-loading-content" color="#0059C6"/>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss">
.c_loading {
  position: absolute;
  width: 100%;
  height: 100%;
  // background: rgba($color: #000000, $alpha: 0.2);
  background: transparent;
  z-index: 999999;
  top: 0;
  .c-loading-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
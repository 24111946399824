import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../components/MainLayout.vue'
import global from '@/utils/global.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainLayout',
    component: MainLayout,
    redirect: '/main',
    children: [
      { path: '/main', name: 'main', component: () => import('@/views/main/Main.vue'), meta: { index: 0, name: '首页' } },
      { path: '/total', name: 'total', component: () => import('@/views/total/Total.vue'), meta: { index: 0, name: '统计' } },
      { path: '/message', name: 'message', component: () => import('@/views/message/Message.vue'), meta: { index: 0, name: '消息' } },
      { path: '/mycenter', name: 'mycenter', component: () => import('@/views/mycenter/MyCenter.vue'), meta: { index: 0, name: '我的' } },
    ],
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: { index: 1 }
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/Register.vue'),
    meta: { index: 1 }
  },
  // 忘记密码
  {
    path: '/forgetpwd',
    name: 'forgetpwd',
    component: () => import('@/views/forgetpwd/ForgetPwd.vue'),
    meta: { index: 2 }
  },
  // 修改登录密码
  {
    path: '/changepwd',
    name: 'changepwd',
    component: () => import('@/views/changepwd/ChangePwd.vue'),
    meta: { index: 1 }
  },
  // 提现
  {
    path: '/cashout',
    name: 'cashout',
    component: () => import('../views/cashout/CashOut.vue'),
    meta: { index: 2 }
  },
  // 提现记录
  {
    path: '/cashouthistory',
    name: 'cashouthistory',
    component: () => import('../views/cashouthistory/CashOutHistory.vue'),
    meta: { index: 2 }
  },
  // 资金明细
  {
    path: '/moneydetail',
    name: 'moneydetail',
    component: () => import('../views/moneydetail/MoneyDetail.vue'),
    meta: { index: 2 }
  },
  // 银行卡绑定
  {
    path: '/changebank',
    name: 'changebank',
    component: () => import('../views/changebank/ChangeBank.vue'),
    meta: { index: 2 }
  },
  // 支付宝绑定
  {
    path: '/changeallpay',
    name: 'changeallpay',
    component: () => import('../views/changeallpay/ChangeAllPay.vue'),
    meta: { index: 2 }
  },
  // 我的邀请码
  {
    path: '/myinvite',
    name: 'myinvite',
    component: () => import('../views/myinvite/MyInvite.vue'),
    meta: { index: 2 }
  },
  // 我的商家
  {
    path: '/mystore',
    name: 'mystore',
    component: () => import('../views/mystore/MyStore.vue'),
    meta: { index: 2 }
  },
   // 我的业务员
   {
    path: '/myywy',
    name: 'myywy',
    component: () => import('../views/myywy/MyYwY.vue'),
    meta: { index: 2 }
  },
  // 我的业务员
  {
    path: '/opennextdaili',
    name: 'opennextdaili',
    component: () => import('../views/opennextdaili/OpenNextDaiLi.vue'),
    meta: { index: 3 }
  },  
  // 我的下级业务员
  {
    path: '/myywysecond/:id',
    name: 'myywysecond',
    component: () => import('../views/myywy/MyYwySecond.vue'),
    meta: { index: 3 }
  }, 
  {
    path: '/refresh',
    name: 'refresh',
    component: () => import('../views/refresh/Refresh.vue'),
    meta: { index: 4 }
  },
]



const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next) => {
  const isLogin = global.token ? true : false;
  const toPath = ['/login', '/forgetpwd']
  if(toPath.includes(to.path)) { // toPath 相当于是路由白名单
    next();
  }else{
    //如果token存在，就正常跳转，如果不存在，则说明未登陆，则跳转到'login'
    isLogin? next() : next("/login");
  }
})

export default router

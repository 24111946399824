<template>
  <van-tabbar class="s_footer_layout_container" v-model="tabIndex" :safe-area-inset-bottom="false" active-color="#0059C6" inactive-color="#9AAFC6" @change="tabBarChange">
      <van-tabbar-item v-for="(item, index) in tabList" :key="index" class="s-tab-item">
        <span>{{item.title}}</span>
        <template #icon>
          <i class="iconfont" :class="item.icon"></i>
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <!-- <div v-for="(item, index) in tabList" :style="tabList.map(item => { return item.name } ).indexOf($route.name) == index ? item.style : {}" :class="tabList.map(item => { return item.name } ).indexOf($route.name) == index ? 'active': ''" class="g-flex g-flex-column g-flex-align-center s-tab-item" :key="index" @click="tabClick(item, index)">
      <i class="iconfont" :class="item.icon"></i>
      <span>{{item.title}}</span>
    </div> -->
</template>

<script>
  export default {
    data() {
      return {
        tabIndex:0,
        tabList: [
          { title: '首页', path: '/main', name: 'main', icon: 'iconshouye'},
          { title: '统计', path: '/total', name: 'total', icon: 'icontongji'},
          { title: '通知', path: '/message', name: 'message', icon: 'icontongzhi'},
          { title: '我的', path: '/mycenter', name: 'mycenter', icon: 'iconIcon_wode'},
        ],
      }
    },
    created() {
      this.tabIndex = this.tabList.map(item => { return item.name } ).indexOf(this.$route.name)
    },
    methods: {
      tabBarChange(val) {
        this.$router.replace({ path: this.tabList[val].path })
      }
    }
  }
</script>

<style lang="scss">
.s_footer_layout_container {
  &.van-tabbar {
    height: 50px;
    bottom: 0px;
    bottom: constant(safe-area-inset-bottom); //苹果底部安全距离
    bottom: env(safe-area-inset-bottom); //苹果底部安全距离
    .s-tab-item {
      font-size: 14px;
      .van-tabbar-item__icon {
        .iconfont {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }

}
</style>